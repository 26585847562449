import { defineComponent, onMounted, ref } from "vue";
import "@sv/elements/expandable";
import "@sv/elements/toggle";
import { Icon } from "./Icon";

export function Accordion(_, { slots }) {
  return (
    <ul class="m-0 list-none p-0">
      <slots.default />
    </ul>
  );
}

Accordion.Item = defineComponent(
  (props: { title: string; subtitle?: string; opened?: boolean }, { slots }) => {
    const loaded = ref(false);

    onMounted(() => {
      loaded.value = true;
    });

    return () => (
      <li class="list-none">
        <a-expandable
          opened={props.opened || undefined}
          class="group mb-0.5 block bg-green-500 transition-color duration-300 [&[opened]]:bg-green-900"
        >
          <button
            // @ts-ignore
            slot="toggle"
            type="button"
            class="grid w-full cursor-pointer grid-cols-[1fr_auto_auto] items-center gap-4 px-6 py-6 text-left"
          >
            <div class="text-white">
              <span>{props.title}</span>
            </div>

            <div class="font-bold text-green-200 text-xs uppercase">
              <span>{props.subtitle}</span>
            </div>

            <div>
              <div class="block text-xs group-[[opened]]:hidden">
                <Icon name="expand-down" />
              </div>
              <div class="hidden text-xs group-[[opened]]:block">
                <Icon name="expand-up" />
              </div>
            </div>
          </button>

          <div class={["px-6 pt-2 pb-12", !loaded.value && "hidden"]}>
            {slots.default?.()}
          </div>
        </a-expandable>
      </li>
    );
  },
  {
    name: "Accordion.Item",
    props: ["title", "subtitle", "opened"],
  },
);
